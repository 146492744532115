import { Id } from "storefront/lib/Id";
import { Listing } from "storefront/Listing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { User } from "storefront/User";

export type SectionName =
  | "grails"
  | "searches"
  | "sellers"
  | "capsules"
  | "designers";

export type FittingRoomSort = "favorited_at" | "price_updated_at";

export type FittingRoomPager = AsyncIterator<
  | {
      hits: Array<AlgoliaListing>;
      tag: "success";
    }
  | {
      rejection: Error;
      tag: "failure";
    }
>;

export type Section = {
  activeSort?: FittingRoomSort;
  error?: Error;
  listings: Array<Listing>;
  loading: boolean;
  noMoreListings: boolean;
  pager?: FittingRoomPager | Record<string, any>;
};

export type FittingRoom = {
  fittingRoomUserId: Id | null | undefined;
  followedSellers: Array<User>;
  mutedListingIds: Array<number>;
  muteRequestId: Id | null | undefined;
  viewing: SectionName;
  capsules: Section;
  grails: Section;
  searches: Section;
  sellers: Section;
  designers: Section;
};

const grails: SectionName = "grails";

const favoritedAt: FittingRoomSort = "favorited_at";

export const empty = {
  fittingRoomUserId: null,
  followedSellers: [],
  mutedListingIds: [],
  muteRequestId: null,
  viewing: grails,
  capsules: {
    listings: [],
    loading: false,
    noMoreListings: false,
  },
  grails: {
    activeSort: favoritedAt,
    listings: [],
    loading: false,
    noMoreListings: false,
    pager: {},
  },
  searches: {
    listings: [],
    loading: false,
    noMoreListings: true,
  },
  sellers: {
    listings: [],
    loading: false,
    noMoreListings: false,
    pager: {},
  },
  designers: {
    listings: [],
    loading: false,
    noMoreListings: false,
  },
};
