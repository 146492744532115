import type { AlgoliaIndexMappings } from "./AlgoliaIndexMappings";
import "./AlgoliaIndexMappings";

/**
 * @name AlgoliaConfig
 * @memberof Config.PublicConfig
 * @description Contains configuration stuff for initializing and working with Algolia.
 */
export type AlgoliaConfig = {
  appId: string;
  indexes: AlgoliaIndexMappings;
  publicBrowseKey: string;
  publicQueryKey: string;
  publicSearchKey: string;
};

export const emptyAlgoliaConfig: AlgoliaConfig = {
  appId: "",
  indexes: {},
  publicBrowseKey: "",
  publicQueryKey: "",
  publicSearchKey: "",
};
