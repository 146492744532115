import type { Reducer, Action as ReduxAction } from "redux";
import type { FittingRoom } from "./index";
import { empty } from "./index";

/**
 * THIS IS A REDUCER TEMPLATE
 *
 * When we're ready to start handling actions in this reducer, please use
 *
 *   client/reducers/fitting_room.js
 *
 * as a template. Keep in mind that `initialState` might cause issues if it relies on `window`.
 *
 * If you're really feeling adventureous, try improving the `Action` type defined below to
 * accurately represent the actions that are handled by this reducer. For an example, check out the
 * Onboarding reducer.
 */
export type Action = ReduxAction;

const reducer: Reducer<FittingRoom, Action> = (state = empty, _action) => state;

export default reducer;
