import { createStore, applyMiddleware } from "redux";
import thunkMiddleware, { ThunkMiddleware } from "redux-thunk";
import type { MakeStore } from "next-redux-wrapper";
import reducer from "./reducer";
import type { GlobalState } from "../GlobalState";
import type { Action } from "./Action";

const bindMiddleware = (middleware: Array<ThunkMiddleware>) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require("redux-devtools-extension");

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const makeStore: MakeStore<GlobalState, Action> = (_context) =>
  createStore(reducer, bindMiddleware([thunkMiddleware]));

export default makeStore;
