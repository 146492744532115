import type { Reducer } from "redux";
import { FETCH_UNREAD_COUNTS_SUCCESS } from "../_app/FetchUnreadCountsSuccessAction";
import type { FetchUnreadCountsSuccessAction } from "../_app/FetchUnreadCountsSuccessAction";
import type { Conversations } from "./index";
import { empty } from "./index";

/**
 * THIS IS AN INCOMPLETE REDUCER
 *
 * If you'd like to start handling more actions in this reducer, please use
 *
 *   client/reducers/conversations.js
 *
 * as a template. Keep in mind that `initialState` might cause issues if it relies on `window`.
 *
 * If you're really feeling adventureous, try improving the `Action` type defined below to
 * accurately represent the actions that are handled by this reducer. For an example, check out the
 * Onboarding reducer.
 */
export type Action = FetchUnreadCountsSuccessAction;

const reducer: Reducer<Conversations, Action> = (state = empty, action) => {
  switch (action.type) {
    case FETCH_UNREAD_COUNTS_SUCCESS:
      return {
        ...state,
        buying: {
          ...state.buying,
          unreadCount: action.payload.buying.unreadCount,
        },
        selling: {
          ...state.selling,
          unreadCount: action.payload.selling.unreadCount,
        },
      };

    default:
      return state;
  }
};

export default reducer;
