import type { Reducer } from "redux";
import { UPDATE_USER_SUCCESS } from "storefront/_app/UpdateUserSuccessAction";
import type { UpdateUserSuccessAction } from "storefront/_app/UpdateUserSuccessAction";
import { FETCH_INITIAL_USER_SUCCESS } from "storefront/_app/FetchInitialUserSuccessAction";
import type { FetchInitialUserSuccessAction } from "storefront/_app/FetchInitialUserSuccessAction";
import {
  TURN_VACATION_ON,
  TURN_VACATION_OFF,
  UNFAVORITE_LISTING_REQUEST,
  UNFAVORITE_LISTING_ERROR,
  FAVORITE_LISTING_REQUEST,
  FAVORITE_LISTING_ERROR,
} from "storefront/actions/constants";
import type { ListingAction } from "storefront/actions/listingAction";
import type { VacationAction } from "storefront/actions/vacationActions";
import { empty as emptyFollowing } from "storefront/Followings";
import type { Session } from "./index";
import { empty } from "./index";

/**
 * THIS IS AN INCOMPLETE REDUCER
 *
 * If you'd like to start handling more actions in this reducer, please use
 *
 *   client/reducers/session.js
 *
 * as a template. Keep in mind that `initialState` might cause issues if it relies on `window`.
 *
 * If you're really feeling adventureous, try improving the `Action` type defined below to
 * accurately represent the actions that are handled by this reducer. For an example, check out the
 * Onboarding reducer.
 */
export type Action =
  | UpdateUserSuccessAction
  | FetchInitialUserSuccessAction
  | ListingAction
  | VacationAction;

// @ts-expect-error ts-migrate(2322) FIXME: Type '(state: Session | undefined, action: Action)... Remove this comment to see the full error message
const reducer: Reducer<Session, Action> = (state = empty, action) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        // TODO: @hsiung making User an exact type will get rid of this error, unfortunately it causes lots of others.
        // Problem here is we are trying to merge User with UserSelf

        currentUser: { ...state.currentUser, ...action.payload },
      };

    case FETCH_INITIAL_USER_SUCCESS:
      return { ...state, initialUserFetched: true };

    case FAVORITE_LISTING_REQUEST:
    case UNFAVORITE_LISTING_ERROR: {
      const { following = emptyFollowing } = state.currentUser;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          following: {
            ...following,
            listings: [...following.listings, action.payload.listingId],
          },
          followedListings: [
            ...state.currentUser.followedListings,
            action.payload.listingId,
          ],
        },
      };
    }

    case UNFAVORITE_LISTING_REQUEST:
    case FAVORITE_LISTING_ERROR: {
      const { following = emptyFollowing } = state.currentUser;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          following: {
            ...following,
            listings: [
              ...following.listings.filter(
                (l) => l !== action.payload.listingId,
              ),
            ],
          },
          followedListings: [
            ...state.currentUser.followedListings.filter(
              (l) => l !== action.payload.listingId,
            ),
          ],
        },
      };
    }

    case TURN_VACATION_ON:
      return {
        ...state,
        currentUser: { ...state.currentUser, vacation: action.payload },
      };

    case TURN_VACATION_OFF:
      return {
        ...state,
        currentUser: { ...state.currentUser, vacation: null },
      };

    default:
      return state;
  }
};

export default reducer;
