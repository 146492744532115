import type { Conversation } from "../Conversation";
import type { Id } from "../lib/Id";

/**
 * @name Context
 * @memberof Conversations
 */
export type Context = {
  conversations: Array<Conversation>;
  nextPage: number;
  loadingConversations: boolean;
  ajaxRequestLoading: boolean;
  expandedConversationIds: Array<Id>;
  expandedSettings: Id | null | undefined;
  activeConversationIds: Array<number>;
  slidDownConversationIds: Array<number>;
  unreadCount: number;
  noMoreConversations: boolean;
  error: Error | null | undefined;
};
export const empty: Context = {
  conversations: [],
  nextPage: -1,
  loadingConversations: false,
  ajaxRequestLoading: false,
  expandedConversationIds: [],
  expandedSettings: null,
  activeConversationIds: [],
  slidDownConversationIds: [],
  unreadCount: 0,
  noMoreConversations: false,
  error: null,
};
export const isExpanded =
  (id: Id) =>
  (context: Context): boolean =>
    id === context.expandedSettings;
export const getConversationById =
  (id: Id) =>
  (context: Context): Conversation | null | undefined =>
    context.conversations.find((convo) => convo.id === id);
