import type { Reducer } from "redux";
import {
  CAPSULES_CMS_MOUNTED,
  CAPSULES_GET_ALL_CAPSULES_ERRORED,
  CAPSULES_GET_ALL_CAPSULES_RESPONDED,
} from "storefront/actions/constants";
import type { Response as GetAllCapsulesResponse } from "storefront/GrailedAPI/v1/Capsules/getAllCapsules";
import type { Capsules } from "./index";
import { empty } from "./index";

/**
 * THIS IS A REDUCER TEMPLATE
 *
 * When we're ready to start handling actions in this reducer, please use
 *
 *   client/reducers/capsules.js
 *
 * as a template. Keep in mind that `initialState` might cause issues if it relies on `window`.
 *
 * If you're really feeling adventureous, try improving the `Action` type defined below to
 * accurately represent the actions that are handled by this reducer. For an example, check out the
 * Onboarding reducer.
 */
type CapsulesCMSMountedAction = {
  type: typeof CAPSULES_CMS_MOUNTED;
};
type CapsulesGetAllCapsulesErroredAction = {
  type: typeof CAPSULES_GET_ALL_CAPSULES_ERRORED;
};
type CapsulesGetAllCapsulesRespondedAction = {
  type: typeof CAPSULES_GET_ALL_CAPSULES_RESPONDED;
  payload: GetAllCapsulesResponse;
};
export type Action =
  | CapsulesCMSMountedAction
  | CapsulesGetAllCapsulesErroredAction
  | CapsulesGetAllCapsulesRespondedAction;

const reducer: Reducer<Capsules, Action> = (state = empty, action) => {
  switch (action.type) {
    case CAPSULES_CMS_MOUNTED:
      return { ...state, capsules: { ...state.capsules, isLoading: true } };

    case CAPSULES_GET_ALL_CAPSULES_RESPONDED:
      return {
        ...state,
        capsules: {
          ...state.capsules,
          isLoading: false,
          data: [...state.capsules.data, ...action.payload.data],
          nextPage: action.payload.metadata.pagination.nextPage,
          hasMoreCapsules: action.payload.data.length > 0,
        },
      };

    case CAPSULES_GET_ALL_CAPSULES_ERRORED:
      return { ...state, capsules: { ...state.capsules, isLoading: false } };

    default:
      return state;
  }
};

export default reducer;
