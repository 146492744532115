import React, { createContext, useContext, useEffect, useState } from "react";
import useAuthentication from "storefront/hooks/useAuthentication";
// Phone Verification Context
type Context = {
  phoneNumberVerified: boolean;
  setPhoneNumberVerified: (arg0: boolean) => void;
};
const PhoneVerificationContext = createContext<Context>({
  phoneNumberVerified: false,
  setPhoneNumberVerified: () => {},
});
export const usePhoneVerificationContext = (): Context =>
  useContext(PhoneVerificationContext);
// Phone Verification Provider
type Props = {
  children: React.ReactElement<React.ComponentProps<any>, any>;
};
export const PhoneVerificationProvider = ({ children }: Props) => {
  const auth = useAuthentication();
  const [phoneNumberVerified, setPhoneNumberVerified] =
    useState<boolean>(false);
  useEffect(() => {
    if (auth.type === "Authenticated") {
      setPhoneNumberVerified(auth.user.is2faVerified);
    }
  }, [auth]);
  return (
    <PhoneVerificationContext.Provider
      value={{
        phoneNumberVerified,
        setPhoneNumberVerified,
      }}
    >
      {children}
    </PhoneVerificationContext.Provider>
  );
};
