import type { Reducer } from "redux";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import type { GlobalState } from "storefront/GlobalState";
import capsules from "storefront/Capsules/reducer";
import config from "storefront/Config/reducer";
import conversations from "storefront/Conversations/reducer";
import filters from "storefront/Filters/reducer";
import fittingRoom from "storefront/FittingRoom/reducer";
import homepage from "storefront/Homepage/reducer";
import listings from "storefront/Listings/reducer";
import onboarding from "storefront/Onboarding/reducer";
import session from "storefront/Session/reducer";
import sellForm from "storefront/SellForm/reducer";
import type { Action } from "./Action";

const reducer: Reducer<GlobalState, Action> = combineReducers({
  capsules,
  config,
  conversations,
  filters,
  fittingRoom,
  form,
  homepage,
  listings,
  onboarding,
  session,
  sellForm,
});

export default reducer;
