import type { Reducer, Action as ReduxAction } from "redux";
import type { Config } from "./index";
import { empty } from "./index";

/**
 * THIS IS AN "IDENTITY REDUCER"
 *
 * "Identity" is a name for something that returns exactly what it is given with no changes.
 *
 * If you'd like to start handling actions in this reducer, please improve the `Action` type with
 * acurate versions of those action objects; see the Onboarding reducer for an example.
 */
export type Action = ReduxAction;

const reducer: Reducer<Config, Action> = (state = empty, _action) => state;

export default reducer;
