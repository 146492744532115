import type { Strata } from "../Strata";
import type { Country } from "../Country";
import type { Badge } from "../Badge";
import {
  AlgoliaConfig,
  emptyAlgoliaConfig,
} from "./PublicConfig/AlgoliaConfig";
import { AlgoliaIndexMapping } from "./PublicConfig/AlgoliaIndexMapping";

// This is the config state in our redux store
export type Config = {
  algolia: AlgoliaConfig;
  algoliaIndexes: Array<AlgoliaIndexMapping>;
  badges: Array<Badge>;
  countries: Array<Country>;
  facebookAppId: number;
  stratas: Array<Strata>;
};
export const empty: Config = {
  algolia: emptyAlgoliaConfig,
  algoliaIndexes: [],
  badges: [],
  countries: [],
  facebookAppId: 0,
  stratas: [],
};
