import { Strata } from "../Strata";

export type Homepage = {
  isSearchingSoldListings: boolean;
  searchableStrata: Array<Strata>;
};

export const empty: Homepage = {
  isSearchingSoldListings: false,
  searchableStrata: [],
};

/**
 * Converts a sort value to a name value to be used to find an Algolia Index. Name values are
 * sort values prefixed with "sold " if the user is searching sold listings.
 * @name Homepage.sortToName
 * @param {string} sort - the value to be converted to a name
 * @param {Homepage} homepage
 * @param {boolean} homepage.isSearchingSoldListings
 * @return {string} the given sort, prefixed with "sold " if the user is searching sold listings
 */
export const sortToName =
  (sort: string) =>
  ({ isSearchingSoldListings }: Homepage): string =>
    isSearchingSoldListings ? `sold ${sort}` : sort;
